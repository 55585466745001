import React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";

class Meta extends Component {
    render() {
        const data = this.props
        return (
            <Helmet
                title={data.title}
                titleTemplate={`%s | ${data.title}`}
                htmlAttributes={{
                    lang: 'it',
                  }}
                meta={
                    [
                        {
                            name: `description`,
                            content: data.metaDescription,
                        },
                        {
                            property: `og:title`,
                            content: data.title,
                        },
                        {
                            property: `og:description`,
                            content: data.metaDescription,
                        },
                        {
                            name: `twitter:title`,
                            content: data.title,
                        },
                        {
                            name: `twitter:description`,
                            content: data.metaDescription,
                        },
                        {
                            name: 'google-site-verification',
                            content:'kMs5TCfRvddQ4MgX7uZ6Q9iEZtBddR0D4vVmE_EY7CE',
                        }
                    ]}
            />
        )
    }
}

export default Meta